import React, { Component } from "react"
import SEO from "../components/seo"
import Map from "../components/map"

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMap: false,
    }
  }

  componentDidMount() {
    this.setState({ showMap: true })
  }

  componentWillUnmount() {
    this.setState({ showMap: false })
  }

  render() {
    const pageContent = (
      <>
        <div className="u-grid u-centered">
          <h2>
            Want to chat through a project or find out more? Here’s where to
            find us.
          </h2>
          <div itemScope itemType="http://schema.org/Organization">
            <p>
              <a href="mailto:hello@squareowl.co.uk" itemProp="email">
                hello@squareowl.co.uk
              </a>
            </p>
            <p>
              <span itemProp="telephone">0161 883 7010</span>
            </p>
            <div
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
            >
              <p>
                <span itemProp="streetAddress">
                  Lancaster Buildings, <br /> 77 Deansgate
                </span>
                , <span itemProp="addressLocality">Manchester</span>.{" "}
                <span itemProp="postalCode">M3 2BW</span>
              </p>
            </div>
          </div>
          {this.state.showMap ? <Map></Map> : null}
        </div>
      </>
    )

    return (
        <main className="o-section">
          <SEO
            title="Contact"
            description="Want to chat through a project or find out more? Here’s where to find us"
            pageClass="s-contact"
          ></SEO>
          {pageContent}
        </main>
    )
  }
}

export default Contact
