import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'; // https://react-google-maps-api-docs.netlify.app/

const containerStyle = {
    height: '300px'
};
  
const center = {
    lat: 53.482169,
    lng: -2.246905
};

const onLoad = marker => {
    console.log('marker: ', marker)
}

const styles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
]


class Map extends Component {
    render() {
      return (
        <div className="c-map">
            <LoadScript
            googleMapsApiKey="AIzaSyC-Y8SMFAdI4_l8_5sxPY9ExHJ3UhqJcd4"
            >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                mapTypeControl={false}
                options={{
                    styles: styles,
                    mapTypeControl: false,
                    panControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                    scrollwheel: false,
                    gestureHandling: 'cooperative',
                    fullscreenControl: false,
                    draggable: false,
                    disableDefaultUI: true
                }}
            >
                { /* Child components, such as markers, info windows, etc. */ }
                <Marker
                    onLoad={onLoad}
                    position={center}
                    title={'Square Owl'}
                    //icon={'https://squareowl.co.uk/favicon.ico'}
                />
                <></>
            </GoogleMap>
            </LoadScript>
        </div>
      )
    }
  }

export default Map
